
export default {
    name: 'ProductViewed',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object
    },
    data() {
        return {
            viewed: {
                visible: false,
                list: []
            },
            swiperCompo: {
                options: {
                    slidesPerView: 'auto',
                },
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getViewedAjax()
    },
    methods: {
        getViewedAjax() {
            this.$api.common.getProductHistory({
                size: 10,
                dd: false
            }).then(response => {
                const res = response.data || {};
                this.viewed.list = res.items || []
                this.viewed.visible = !!this.viewed.list.length
                this.setData()
            })
        },
        setData() {
            this.viewed.list.forEach(p => {
                const first = (p.pics && p.pics[0]) || {};
                p.rsPic = first.url
                p.hasOrg = p.orgPrice > p.salePrice
            })
        },
        handleProduct(obj) {
            const query = this.$route.params;
            if(query.code == obj.productCode) {
                this.$router.go(0)
                return
            }
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: obj.productCode,
                    seoUrl: obj.seoUrl
                }
            })
        }
    },
}
