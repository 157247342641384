

export default {
    name: 'ProductSubmit',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        currency: Object,
        info: Object,
        skuData: Object,
        skuSelect: Object,
        vip: Object,
        rec: Object
    },
    data() {
        return {
            hasLogin: false,
            loading: false,
            wish: {                  // 收藏状态
                status: false,
                hasTip: false
            },
            btn: {                   // 按钮状态
                hasAdd: false,
                hasCustomize: false,
                hasSold: false,
                hasBtn: false
            },
            customizeInfo: {},       // 定制信息
            customizeRules: {        // 定制规则
                hasRequire: true,
                onceSkuSelect: false,
            },
            bagCompo: {              // 加购组件
                hasBag: false,
                hasOnce: false,
                type: 1, // 1 加购 2 定制
                options: {
                    visible: false,
                    hasImdAddBag: false,
                    hasRequest: false,
                    hasShowDetail: false,
                    btnText: ''
                },
                sku: {
                    skuId: '',
                    skuGId: '',
                    skuMId: ''
                },
            },
            customizeCompo: {        // 定制组件
                visible: false,
                hasPopup: true,
                url: '',
                on: {}
            },
            params: {
                quantity: 1,
                main_cart_id: '',     // 加购id
                add: [],              // 加购参数
                addLog: [],           // 加购日志
                customUrl:''          // 定制图片
            },
            wishTime:null
        }
    },
    computed: {},
    watch: {
        skuData: {
            handler() {
                this.changeSkuData()
            },
            deep: true
        },
        skuSelect: {
            handler() {
                this.changeSku()
            },
            deep: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
        this.setBtnStatus()
        // this.hasLogin && this.info.id && this.getWishStatusAjax()
    },
    methods: {
        // 获取参数
        getAddParams() {
            const skuSelect = this.skuSelect || {};
            let addObj = {
                skuId: skuSelect.id,
                spuId: skuSelect.spuId,
                quantity: this.params.quantity || 1,
                customizeType: this.info.customizeType,
                isVipService: this.vip.isVipService
            };
            // 加购参数
            this.params.add = [addObj]
            // 加购日志参数
            this.params.addLog = [{
                skuId: skuSelect.id,
                spuId: skuSelect.spuId,
                quantity: this.params.quantity || 1,
                customizeType: this.info.customizeType,
            }]
        },
        // 操作-加购---------------------------------
        setAddAjax() { // 加购接口
            this.$api.product.setAddBag({
                data: JSON.stringify(this.params.add)
            }).then(response => {
                const res = response.result || {};
                if(response.code == 'success') {
                    const cartId = res.cartId || [];
                    this.params.main_cart_id = cartId[0] || ''
                    this.showAddSuccessPopup()
                }
                this.loading = false
            })
        },
        handleAdd() { // 加购-按钮
            const skuSelect = this.skuSelect || {};
            this.bagCompo.type = 1
            if(skuSelect.noSelectName){
                this.$Toast(this.$translate('Please Choose') + ' ' + skuSelect.noSelectName)
                return
            }
            if(skuSelect.hasAllSelect) {
                this.handleAddSet()
            } else {
                this.bagCompo.options.btnText = this.$translate('ADD TO BAG')
                this.setSkuComo()
            }
        },
        handleAddSet() { // 加购-加入购物车
            this.loading = true
            this.getAddParams()
            this.setAddAjax()
            // 加购埋点
            this.setBuried()
        },
        showAddSuccessPopup() { // 加购-加购成功弹窗显示
            this.bagCompo.hasBag = false
            if(this.info.afterAddCartStyle == 2) {
                this.middleEmit.fn('showAddSuccessPopup', true, {
                    main_cart_id: this.params.main_cart_id
                })
            }else if(this.info.afterAddCartStyle == 4) {
                this.middleEmit.fn('showAddSuccessPopup', true, {
                    type: 4,
                    ...this.params
                })
            }else if(this.info.afterAddCartStyle == 5) {
                this.middleEmit.fn('showAddSuccessPopup', true, {
                    type: 5,
                    ...this.params
                })
            }else if(this.info.afterAddCartStyle == 3){
                this.$router.push({
                    name: 'Bag',
                })
            } else {
                this.$Toast(this.$translate('Added To Bag Successfully'))
                if(!this.rec.isIntersecting) {
                    this.$fnApi.debounce(() => {
                        const tag = document.querySelector('.ProductRec');
                        tag && this.$VueScrollTo.scrollTo('.ProductRec', '', {
                            offset: 0 //-55
                        })
                    }, 2000)
                }
            }
            this.$updateBagQuantity()
            // 加购后
            this.middleEmit.fn('handleAddSuccess', {
                ...this.params
            })
            // 加购日志
            this.setAddLog()
        },
        // 操作-定制---------------------------------
        handleCustomize() { // 定制-定制弹窗显示
            const skuSelect = this.skuSelect || {};
            this.bagCompo.type = 2
            if(skuSelect.hasAllSelect) {
                this.showCustomize()
            } else {
                this.bagCompo.options.btnText = this.$translate('Customize Now')
                this.setSkuComo()
            }
        },
        setCustomizeConfig() { // 定制-配置
            const skuSelect = this.skuSelect || {};

            let queryArr = [];
            let queryStr = '';
            let query = {
                spu: skuSelect.spuId,
                sku: skuSelect.id,
                productCode: this.info.productCode,
                isVipService: this.vip.isVipService
            };
            Object.keys(query).forEach(key => {
                queryArr.push(`${key}=${query[key]}`)
            })
            queryStr = queryArr.join('&')
            // this.customizeCompo.visible = true
            this.customizeCompo.url = `${location.origin}/custonIndex?${queryStr}`
            this.customizeCompo.on.setCustomizeDone = this.setCustomizeDone
            // this.bagCompo.hasBag = false
        },
        showCustomize() { // 定制-显示定制弹窗
            !this.customizeCompo.url && this.setCustomizeConfig()
            this.customizeCompo.visible = true
            this.bagCompo.hasBag = false
        },
        setCustomizeDone(obj = {data: '{}'}) { // 定制-定制完成
            const data = JSON.parse(obj.data);
            this.params.main_cart_id = data.mainCartId
            let customizeInfo = data.customizeInfo || [];
            this.params.customUrl = customizeInfo[0]?.url
            this.customizeCompo.visible = false
            this.setBuried()
            this.showAddSuccessPopup()
            this.$updateBagQuantity()
        },
        // 按钮状态设置----------------------------------------------
        getWishStatusAjax() { // 获取商品收藏状态
            this.$api.product.getWishStatus({
                spuId: this.info.id,
                skuId: this.skuSelect.id
            }).then(response => {
                const res = response.result || {};
                this.wish.status = res.status
            })
        },
        getCustomizeAjax() { // 获取定制化规则
            this.$api.product.getCustomize({
                spuId: this.info.id,
                skuId: this.skuSelect.id
            }).then(response => {
                // customizeType 1 普通规格 2 定制规格 3 可定制/可加购规格
                const res = response?.result || {};
                this.customizeInfo = res
                this.customizeRules.hasRequire = this.customizeInfo.customizeType == 2
                this.setBtnStatus()
                if(this.customizeInfo.customizeType != 1) {
                    this.setCustomizeConfig()
                }
            })
        },
        setBtnStatus() { // 按钮状态
            this.btn.hasBtn = true
            this.btn.hasSold = !this.info.onShelf
            const customizeType = this.customizeInfo.customizeType || this.info.customizeType;
            this.btn.hasAdd = customizeType != 2
            this.btn.hasCustomize = customizeType != 1
        },
        // 加购弹窗-sku数据同步-------------------------------------
        emitSkuConfirm(obj) {
            const skuSelect = this.skuSelect || {};
            if(skuSelect.hasAllSelect) {
                this.params.quantity = obj?.options?.quantity || 1
                if(this.bagCompo.type == 1) {
                    // 加购
                    this.handleAddSet()
                } else {
                    // 定制
                    this.showCustomize()
                }
            }
        },
        emitSkuSelect(obj) {
            this.$fnApi.debounce(() => {
                if(!this.bagCompo.hasOnce) {
                    this.middleEmit.fn('skuSelectPopup', obj)
                }
                this.bagCompo.hasOnce = false
            }, 100)
        },
        emitBagClose() {
            this.bagCompo.hasBag = false
            this.params.quantity = 1
        },
        setSkuComo() { // 设置弹窗sku
            const skuSelect = this.skuSelect || {};
            const optionsSelect = skuSelect.optionsSelect || []
            let hasStatus = false;
            if(!skuSelect.hasAllSelect) {
                optionsSelect.some(p => {
                    if(p.status) {
                        this.bagCompo.sku.skuGId = p.id
                        this.bagCompo.sku.skuId = p.list.id
                        hasStatus = true
                        return true
                    }
                })
            }
            this.bagCompo.hasBag = true
            this.$fnApi.debounce(() => {
                hasStatus && (this.bagCompo.hasOnce = true)
                this.bagCompo.options.visible = true
            }, 100)
        },
        // emit
        changeSkuData() { // sku数据变化
            if(!this.onceSkuSelect) {
                this.changeSku()
            } else {
                this.setBtnStatus()
            }
        },
        changeSku() { // sku变化
            this.onceSkuSelect = true
            this.$fnApi.debounce(() => {
                // if(this.info.stockModel != 2 && this.info.customizeType == 2 && !this.skuSelect.hasOnlySku && this.skuSelect.hasAllSelect) {
                if(this.info.stockModel != 2 && this.info.customizeType == 2 && this.skuSelect.hasAllSelect) {
                    this.getCustomizeAjax()
                } else {
                    this.customizeRules.hasRequire = this.skuSelect.hasOnlySku && this.info.customizeType == 2
                    this.customizeInfo = {}
                    this.setBtnStatus()
                    // this.info.customizeType == 2 && this.skuSelect.hasAllSelect && this.setCustomizeConfig()
                }
                this.hasLogin && this.skuSelect.hasAllSelect && this.getWishStatusAjax()
            }, 100)
        },
        // 加购日志
        setAddLogAjax() { // 加购结算日志接口
            let log = {
                addPage: 2,
                traceType: 1,
                spuItems: this.params.addLog,
                settleSource: this.info.id,
            };
            this.$api.product.setAddBagLog({
                ...log
            }).then(response => {})
        },
        setAddLog() {
            this.setAddLogAjax()
        },
        // mix
        setWishAjax() { // 收藏接口
            this.$api.product.setWishStatus({
                skuId: this.skuSelect.id,
                spuId: this.info.id,
                status: !this.wish.status
            }).then(response => {
                const res = response?.result || {};
                this.wish.status = res.status
                this.wish.hasTip = true
                if(this.wishTime){
                    clearTimeout(this.wishTime)
                }
                this.wishTime = setTimeout(()=>{
                    this.wish.hasTip = false
                },3000)
                // this.$fnApi.debounce(() => {
                //     this.wish.hasTip = false
                // }, 3000)
            })
        },
        handleWish() { // mix-收藏
            if(!this.hasLogin) {
                this.$router.push({
                    name: 'Login'
                })
            } else {
                this.$fnApi.debounce(() => {
                    this.setWishAjax()
                }, 100)
            }
        },
        handleWishTip() { // 收藏提示操作
            this.wish.hasTip = false
            if(this.wish.status) {
                this.$router.push({
                    name: 'Wish'
                })
            }
        },
        // 客服
        handleCustomer() {
            if(ECHAT) {
                ECHAT.customMiniChat()
                this.listenerEChat()
            }
        },
        listenerEChat() {
            _echat('on',{
                newEvent: (e) => {
                    let status = false
                    if(['showMiniWin', 'newSysMsg'].includes(e.eventAction)) {
                        status = true
                    } else {
                        if(['leaveToService'].includes(e.eventLabel)) {
                            status = true
                        }
                    }
                    status && this.setEChat()
                }
            });
        },
        setEChat() {
            let visEvt = {
                eventId: this.info.productCode,
                title: this.info.productName,
                content: `<div style="font-size: 13px;color: #333;line-height: 7px;">
                            <div style="display:flex;align-item: center;color: #f00;">${this.currency.symbol}<span>${this.info.displaySalePrice}</span></div>
                        </div>`,
                imageUrl: this.info.pics[0].url,
                urlForVisitor: `http(${location.href}, 'inner')`,
                urlForStaff: `${location.href}`,
            }
            ECHAT.pushVisitorEvent(visEvt)
        },
        // 加购埋点
        setSymbolReplace(val) {
            try {
                return val && val.includes(',') ? val.replaceAll('.', '').replaceAll(',', '.').replace(/[^\d.-]/g, '') : val
            } catch {
                return val
            }
        },
        setBuried() {
            const select = this.skuSelect || {};
            // tiktok
            this.$fnApi.buried.tiktok({
                type: 'ClickButton',
                params: {
                    content_type: 'product',
                    content_id: this.info.productCode,
                    currency: 'USD',
                }
            })
            this.$fnApi.buried.tiktok({
                type: 'AddToCart',
                params: {
                    content_type: 'product',
                    quantity: this.params.quantity,
                    content_id: this.info.productCode,
                    currency: this.currency.code,
                    price: select.displayMarketPrice,
                    value: this.setSymbolReplace(select.displayMarketPrice)
                }
            })
            // gtag 加购事件埋点
            this.$fnApi.buried.gtag({
                type: 'conversion_cart',
                params: {
                    'value': this.setSymbolReplace(select.displayMarketPrice),
                    'currency': this.currency.code
                }
            })
            this.$fnApi.buried.gtag({
                type: 'add_to_cart',
                params: {
                    items: [{
                        id: this.info.productCode,
                        name: this.info.productName,
                        quantity: this.params.quantity,
                        price: this.setSymbolReplace(select.displayMarketPrice)
                    }]
                }
            })
            // criteo 埋点
            // this.$fnApi.buried.criteo({
            //     type: 'addToCart',
            //     params: [{
            //         id: this.info.productCode,
            //         price: this.setSymbolReplace(select.displayMarketPrice),
            //         quantity: this.params.quantity,
            //     }]
            // })
            // fbq 埋点
            this.$fnApi.buried.fbq({
                type: 'AddToCart',
                params: {
                    content_type: 'product',
                    content_ids: this.info.productCode,
                    currency: 'USD',
                    value: this.setSymbolReplace((select.salePrice/100).toFixed(2)),
                    contents: [{
                        id: this.info.productCode,
                        quantity: this.params.quantity,
                    }]
                }
            }, this)
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
        }
    },
}
