

export default {
    name: 'ProductSustain',
    props: {
        info: Object,
    },
    data() {
        return {
            collapse: {
                active: [1],
                visible: true
            },
        }
    },
    created() {},
    mounted() {
    },
    methods: {
        // 折叠面板
        changeCollapse(e) {
            this.collapse.visible = e
        },
    },
}
