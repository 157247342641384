
export default {
    name: '',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        info: Object,
        middleEmit: Object
    },
    data() {
        return {
            rsInfo: {},
            vip: {
                visible: false,
                isVipService: false,
            },
            popup: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            if(this.rsInfo.vipService) {
                this.vip = {...this.vip, ...this.rsInfo.vipService}
                this.vip.visible = true
                this.emitVipData()
            }
        },
        handleChoose() {
            this.vip.isVipService = !this.vip.isVipService
            this.emitVipData()
        },
        handleTip() {
            this.popup.visible = true
        },
        emitVipData() {
            this.middleEmit.fn('setVipData', this.vip)
        }
    }
}
